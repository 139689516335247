import { ChainId, useEthers } from '@usedapp/core';
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import abi from '../../abi/stakingContractAbi.json';
import tokenAbi from '../../abi/tokenAbi.json';
import colAbi from '../../abi/collectionAbi.json';
import { Link } from 'react-router-dom';

// import { Modal } from "react-responsive-modal";
import NftInventoryModal from '../Modal/NftInventoryModal';
import StakingPoolInventoryModal from '../Modal/StakingPoolInventoryModal';
import { useConnectWallet } from '@web3-onboard/react';
import Countdown from 'react-countdown';
// import { useLazyQuery, useMutation } from '@apollo/client';
// import { collectionData } from '../../graphql/collections/collectionQueries';
import { useNavigate, useParams } from 'react-router';
import { updateCollection } from '../../graphql/collections/collectionMutations';
import { networkHashMap } from '../common/constants';
import { useSetChain } from '@web3-onboard/react';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ethers } from 'ethers';
import UnstakeNftInventoryModal from '../Modal/UnstakeNftInventoryModal';
import { saveCollection } from '../../redux/action';

const StakingPool = ({ renderData }) => {
  console.log("renderData:", renderData)
  const [{ connectedChain, chains }] = useSetChain();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenUnstake, setIsModalOpenUnstake] = useState(false);
  const [loader, setLoader] = useState('');
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [reward, setReward] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalStaked, setTotalStaked] = useState(0);
  const [myStaked, setMyStaked] = useState(0);
  const [withdrawFee, setWithdrawFee] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [rewardsPerWeek, setRewardsPerWeek] = useState();
  const [rewardsDuration, setRewardsDuration] = useState(null);
  const [rewardAmount, setRewardAmount] = useState(null);
  const [duration, setDuration] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [chainId, setChainId] = useState(null);
  const [decimals, setDecimals] = useState(18);
  const [contractOwner, setContractOwner] = useState('');
  const [{ wallet }] = useConnectWallet();
  const [claimParams, setClaimParams] = useState([])
  const [disbaleStakeUnstake, setDisableStakeUnstake] = useState(false)
  const { contract_address, collection_address, token_address } = renderData;
  console.log("contract_address:", contract_address)
  const [boostNft1Addr, setBoostNft1addr] = useState('')

  const etherProvider = () => {
    try {
      return new ethers.providers.Web3Provider(wallet?.provider);
    } catch (error) { }
  };

  const etherSigner = etherProvider()?.getSigner();
  const etherContractInst = new ethers.Contract(
    contract_address?.toLowerCase(),
    abi,
    etherSigner
  );
  const etherTokenContractInst = new ethers.Contract(
    token_address?.toLowerCase(),
    tokenAbi,
    etherSigner
  );

  const etherCollContrInst = new ethers.Contract(
    collection_address?.toLowerCase(),
    colAbi,
    etherSigner
  );

  useEffect(() => {
    if (wallet) {
      getSymbol();
    }
    return () => { };
  }, [wallet]);
  const getSymbol = async () => {
    try {
      let symbol = await etherTokenContractInst.symbol();
      setSymbol(symbol);
      let decimal = await etherTokenContractInst.decimals();
      setDecimals(decimal);
    } catch (error) {
      console.log('🚀 ~ getSymbol ~ error', error);
    }
  };

  const web3 = new Web3(wallet?.provider);

  const contractInstance = new web3.eth.Contract(abi);
  contractInstance.options.address = contract_address?.toLowerCase();

  const collectionContractInstance = new web3.eth.Contract(colAbi);
  collectionContractInstance.options.address =
    collection_address?.toLowerCase();

  // set the chargeStake, chargeWithdraw, chargeClaim needs to be set.
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCompleted(false);
      return (
        <span className="complete_countdown_text">Countdown complete!</span>
      );
    } else {
      return (
        <span className="time_part">
          {days}d {hours}h:{minutes}m:{seconds}s
        </span>
      );
    }
  };

  useEffect(() => {
    const body = document.querySelector('html');
    body.style.overflow = isModalOpen ? 'hidden' : 'scroll';
  }, [isModalOpen]);

  const navigate = useNavigate();

  // const [UpdateCollection] = useMutation(updateCollection);

  const dateInputHandler = (e) => {
    console.log("e: date input", e.target.value)
    let _multiplyByWeek = e?.target?.value * 604800
    console.log("_multiplyByWeek:", _multiplyByWeek)
    setDuration(parseInt(new Date().getTime() + _multiplyByWeek * 1000));
  };

  const rewardsDurationHandler = async () => {
    try {
      setBtnLoading(true);
      let data = {
        _id: renderData._id,
        expire_date: `${duration}`
      };
      let _addDate = axios
        .put(`${process.env.REACT_APP_API_URL}nfts`, data)
        .then((res, err) => {
          if (err) {
            setLoading(false);
            throw err;
          }
          setBtnLoading(false);

          navigate('/');
          return res;
        });

    } catch (error) {
      console.log('🚀 ~ rewardsDurationHandler ~ error', error);
      toast.error('Something went wrong');
    }
  };

  const calculateGas = async (params) => {
    // console.log("params:", params)
    try {
      let gasss = await etherContractInst.calculateFee(
        wallet?.accounts[0]?.address, //user address
        params[0].length, //length of nft
        params[1], //isboost
        params[3], //boostnftid
        params[2] //boostnftid
      );
      // console.log('🚀 ~ calculateGas ~ gasss', gasss.toString());
      return gasss.toString();
    } catch (error) {
      console.log('🚀 ~ calculateGas ~ error', error);
    }
  };

  const handleStake = async (selectedNfts, recentSelected) => {
    console.log("handleStake: function start",)
    console.log("recentSelected:", recentSelected)
    // console.log("boostNft2:", boostNft2)
    // console.log("boostnft:", boostnft)
    // let _boostNft=boostNft2.length?boostNft2
    // return
    // console.log("boostnft:", boostnft)
    // if (!boostnft.length) {
    //   setIsLoading(false)
    //   return toast.error("Please select boost nft")
    // }
    // console.log("boostNftAddr:", boostNftAddr)
    // console.log('🚀 ~ handleStake ~ selectedNfts', selectedNfts);
    // console.log('🚀 ~ handleStake ~ selectedboostnftNfts', boostnft);
    let stakeUrl = `${process.env.REACT_APP_API_URL}stake`;

    try {
      let _callStake = await etherCollContrInst.isApprovedForAll(wallet?.accounts[0]?.address, contract_address)
      console.log("_callStake:", _callStake)
      if (!_callStake) {
        console.log("ifff")

        let setApproved = await etherCollContrInst.setApprovalForAll(contract_address, true)
        let waitForApproved = await setApproved.wait();
        if (waitForApproved) {
          let params;
          if (recentSelected?.tokenId?.length > 0) {
            params = [[...selectedNfts], true, recentSelected?.contractAddr, recentSelected?.tokenId[0]];
            console.log(" ifffparams:", params)
          } else {
            params = [[...selectedNfts], false, recentSelected?.contractAddr, 1];
            console.log("ifff elseparams:", params)
          }
          let _gas = await calculateGas(params);
          console.log("params:", params)
          try {
            let _tx = await etherContractInst.stake(...params, { value: _gas })
            let waitForTx = await _tx.wait()
            if (waitForTx) {
              let obj = {
                user_address: wallet?.accounts[0].address,
                collections: [
                  {
                    collection_address: collection_address,
                    stakingContractAddr: contract_address,
                    token_id: selectedNfts,
                    boosnftTokenId: recentSelected?.tokenId,
                  }
                ]
              };
              let _callApi = await axios.post(stakeUrl, obj).then((res, err) => {
                if (!err) {
                  return res;
                }
              });
              setIsModalOpen(false);
              setIsLoading(false);
              getTotalStaked();
              toast.success('Successfully stake!');
            }
          } catch (err) {
            setIsLoading(false);
            let _stringify = JSON.stringify(err);
            let _parse = JSON.parse(_stringify);
            if (_parse?.error?.message) {
              toast.error(_parse?.error?.message);
              setIsLoading(false);
            }
            if (_parse?.message) {
              toast.error(_parse?.message);
              setIsLoading(false);
            }
          }
        }
      }
      else {
        console.log("else")
        let params;
        console.log("recentSelected?.tokenId?.length:", recentSelected?.tokenId?.length)
        console.log("recentSelected?.tokenId?.length:", recentSelected?.tokenId?.length > 0)
        if (recentSelected?.tokenId?.length > 0) {
          console.log("else if")
          params = [[...selectedNfts], true, recentSelected?.contractAddr, recentSelected?.tokenId[0]];
          console.log("params:", params)
        } else {
          params = [[...selectedNfts], false, recentSelected?.contractAddr, 1];
          console.log("params:", params)
        }
        let _gas = await calculateGas(params);
        let _tx = await etherContractInst.stake(...params, { value: _gas })
        let waitForTx = await _tx.wait()
        if (waitForTx) {
          let obj = {
            user_address: wallet?.accounts[0].address,
            collections: [
              {
                collection_address: collection_address,
                stakingContractAddr: contract_address,
                token_id: selectedNfts,
                boosnftTokenId: recentSelected?.tokenId,
              }
            ]
          };

          let _callApi = await axios.post(stakeUrl, obj).then((res, err) => {
            if (!err) {
              return res;
            }
          });
          setIsModalOpen(false);
          setIsLoading(false);
          getTotalStaked();
          toast.success('Successfully stake!');
        }
      }

    } catch (error) {
      console.log('🚀 ~  ~ error', error);
      setIsLoading(false);

    }
  };
  const handleUnstake = async (selectedNfts) => {
    console.log("selectedNfts for remove:", selectedNfts)
    try {
      console.log("selectedNfts:", selectedNfts)
      let _tx = await etherContractInst.removeFromStake(selectedNfts)
      let waitForTx = await _tx.wait()
      if (waitForTx) {
        let address = wallet?.accounts[0].address;
        let nfts = selectedNfts.toString();

        let url = `${process.env.REACT_APP_API_URL}stake?user_address=${address}&token_id=${nfts}&collection_address=${collection_address}`;
        let _removeFromDb = await axios.put(url);
        console.log("_removeFromDb:", _removeFromDb)

        getTotalStaked()
        setIsLoading(false)
        setIsModalOpenUnstake(false)
        toast.success('Successfully unstake!');

      }

    } catch (err) {
      setIsLoading(false);
      let _stringify = JSON.stringify(err);
      let _parse = JSON.parse(_stringify);
      if (_parse?.error?.message) {
        toast.error(_parse?.error?.message);
        setIsLoading(false);
      }
      if (_parse?.message) {
        toast.error(_parse?.message);
        setIsLoading(false);
      }
    }
    // console.log("handle unstake")
  }

  const handleWithdraw = async (selectedNfts) => {
    const params = [collection_address.toLowerCase(), [...selectedNfts]];
    const gasEstimate = await contractInstance.methods['withdraw'](
      ...params
    ).estimateGas({
      from: wallet?.accounts[0]?.address,
      value: selectedNfts?.length * withdrawFee
    });
    const gasLimit = gasEstimate * 2;
    const tx = await contractInstance.methods['withdraw'](...params)
      .send({
        from: wallet?.accounts[0]?.address,
        value: selectedNfts?.length * withdrawFee,
        gas: gasLimit
      })
      .then(async (data) => {
        // const receipt = await web3.eth.getTransactionReceipt(
        //   tx.transactionHash
        // );
        // console.log("receipt", receipt.status);
        setIsWithdrawModalOpen(false);
        setIsLoading(false);
        getTotalStaked();
      })
      .catch((err) => {
        console.log('err', err);
        setIsLoading(false);
      });
    // const gasFee = await calculateGas();

    // contractInstance.methods
    //   .withdraw(collection_address, [...selectedNfts])
    //   .send({
    //     from: wallet?.accounts[0]?.address,
    //     value: selectedNfts.length * withdrawFee,
    //     // value: boostedCount > 1 ? "0" : selectedNfts.length * withdrawFee,
    //     // gas: selectedNfts.length == 1 ? 350000 : selectedNfts.length * 180000,
    //     gasLimit:
    //       wallet.label != "MetaMask" &&
    //       (selectedNfts.length == 1 ? 262500 : selectedNfts.length * 131250),
    //     gasPrice: web3.utils.toWei(
    //       gasFee.speeds[2].maxFeePerGas.toString(),
    //       "gwei"
    //     ),
    //   })
    //   .then((receipt) => {
    //     console.log("receipt=======>", receipt);
    //     setIsWithdrawModalOpen(false);
    //     setIsLoading(false);
    //     getTotalStaked();
    //   })
    //   .catch((err) => {
    //     console.log("error=======>", err);
    //     setIsLoading(false);
    //   });
  };
  const _chargeClaim = async () => {
    //new
    let _gas = await etherContractInst.chargeClaim();
    //old
    // let _gas = await contractInstance.methods.chargeClaim().call();
    return _gas;
  };
  const getBoostNft1Addr = async () => {
    console.log("getBoostNft1Addr:")
    //new
    let _addr = await etherContractInst.boostNFT1();
    console.log("_addr:", _addr)
    setBoostNft1addr(_addr)
    //old
    // let _gas = await contractInstance.methods.chargeClaim().call();
    // return _gas;
  };


  const getAdmin = async () => {
    try {
      console.log("etherContractInst:", etherContractInst)
      let _admin = await etherContractInst.owner();
      // console.log("_admin:", _admin)
      setContractOwner(_admin);
    } catch (error) {
      console.log("error:", error)
    }
  };
  useEffect(() => {

    checkSoldNfts()
    getBoostNft1Addr()
    return () => {

    }
  }, [])

  const checkSoldNfts = async () => {
    console.log("checksold nft function")
    try {


      let url = `${process.env.REACT_APP_API_URL}stake?collectionAddr=${collection_address}&user_address=${wallet?.accounts[0]?.address}`;
      let { data } = await axios.get(url)
      let getId = data[0]?.collections.filter((e) => {
        return (
          e.collection_address?.toLowerCase() == collection_address?.toLowerCase()
        );
      });
      console.log("getId:", getId[0]?.token_id)
      let getSoldTokenId = await etherContractInst.compareSoldTokenId(getId[0].token_id);
      console.log("getSoldTokenId:", getSoldTokenId.toString())
      console.log("getSoldTokenId:", getSoldTokenId.length)
      if (getSoldTokenId?.length > 0) {
        setDisableStakeUnstake(true)
        let arr = []
        for (let k = 0; k < getSoldTokenId?.length; k++) {
          const element = getSoldTokenId[k].toString();
          // console.log("element:", element)
          arr.push(getSoldTokenId[k].toString())

        }
        // console.log("aaaaa----", arr)
        setClaimParams(arr)
      }

    } catch (error) {
      console.log("error:", error)

    }

  }

  const handleClaim = async () => {
    try {


      let _gas = await _chargeClaim();
      setLoading(true);
      let _claim = await etherContractInst.claimReward(claimParams, {
        value: _gas.toString()
      });
      let _wait = await _claim.wait();

      if (_wait) {
        let nfts = claimParams.toString();
        const address = wallet?.accounts[0].address

        let url = `${process.env.REACT_APP_API_URL}stake?user_address=${address}&token_id=${nfts}&collection_address=${collection_address}`;
        let _removeFromDb = await axios.put(url);
        setLoading(false);
        toast.success('Successfully claim!');
        calculateReward();
        checkSoldNfts()
        getTotalStaked()
        setDisableStakeUnstake(false)
      }
    } catch (err) {
      console.log('err', err);
      let er = JSON.stringify(err);
      let _par = JSON.parse(er);
      if (_par?.reason) {
        toast.error(_par?.reason);
      }
      // console.log('🚀 ~ handleClaim ~ _par', _par);
      setLoading(false);
    }
  };

  const getTotalStaked = async () => {
    console.log("getTotalStaked:")
    try {
      console.log("get total staked call")
      let totalStaked = await etherContractInst.totalStaked(collection_address);
      let myStake = await etherContractInst.balanceOf(
        wallet?.accounts[0].address
      );
      if (myStake) {
        setMyStaked(myStake.toString());
      }
      if (totalStaked) {
        setTotalStaked(totalStaked.toString());
        calculateReward();
        setIsLoading(false);
      }
    } catch (error) {
      console.log('🚀 ~ getTotalStaked ~ error', error);
    }
  };

  // useEffect(() => {
  //   if (wallet && wallet.label) {
  //     setDisplay(wallet.label + `${wallet.label != "MetaMask"}`);
  //   }
  // }, [wallet]);

  const calculateReward = async () => {
    // compareSoldTokenId
    if (wallet?.accounts[0]?.address) {
      try {
        let _cal = await etherContractInst.earned(
          collection_address,
          wallet?.accounts[0]?.address
        );
        setReward(_cal.toString());
      } catch (error) {
        console.log('🚀 ~ useEffect ~ error', error);
      }
    }
  };
  useEffect(async () => {
    if (wallet) {
      getTotalStaked();
      getAdmin();
    }
  }, [renderData, reward, loading, wallet]);
  useEffect(() => {
    // console.log('🚀 ~ .then ~ calculate  Reward');
    if (wallet) {
      calculateReward();
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet) {
      getTotalStaked();
    }

    return () => { };
  }, [loading]);

  useEffect(async () => {

    if (wallet) {
      setRewardAmountMethod();
    }
  }, [renderData, wallet]);
  const setRewardAmountMethod = async () => {
    try {
      let _durati = await contractInstance.methods.rewardsDuration().call();
      let _reward = await contractInstance.methods.rewardAmount().call();
      setRewardsDuration(_durati);
      setRewardAmount(_reward);
      setRewardsPerWeek(parseInt(_reward) / (parseInt(_durati) / 604800));
    } catch (error) {
      console.log('🚀 ~ setRewardAmountMethod ~ error', error);
    }
  };

  useEffect(() => {
    setRewardsPerWeek(
      parseInt(rewardAmount) / (parseInt(rewardsDuration) / 604800)
    );
  }, [rewardsDuration]);


  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT == 'DEV') {
      setChainId(ChainId.CronosTestnet);
    } else {
      setChainId(ChainId.Cronos);
    }
  }, [wallet]);

  const modalHandler = () => {
    setIsModalOpen(!isModalOpen);
  };
  const modalHandler2 = () => {
    setIsModalOpenUnstake(!isModalOpenUnstake);
  };


  const extractImageId = (addr) => {
    console.log('🚀 ~ extractImageId ~ addr', addr)
    let url = addr.split('/')[5];
    console.log('🚀 ~ extractImageId ~ url', url)
    return url;
  };

  return (
    <>
      <ToastContainer />
      {/* {true && ( */}
      <section className="stack_two_new_list">
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-12">
              <div className="stackCardWrapper">
                {wallet &&
                  contractOwner?.toLowerCase() ==
                  wallet.accounts[0].address.toLowerCase() && (
                    <div className='text-center'>
                      <Link
                        to={`/admin/${contract_address}`}
                        className="project-link text-center"
                        style={{ textAlign: 'center', fontWeight: '500', fontSize: '20px' }}
                        state={renderData}
                      >
                        Update Contract
                      </Link>
                    </div>
                  )}

                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="staking-btn d-block text-left w-100 pt-4 pb-2 py-md-4"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#collapse${renderData?._id}`}
                    >
                      <div className="row ">
                        <div className="col-12 col-md-7 ">
                          <div className="media flex-column flex-md-row align-items-center align-items-md-start">
                            <img
                              className="avatar-max-lg "
                              src={
                                renderData?.picture
                                  ? `https://drive.google.com/thumbnail?id=${extractImageId(
                                    renderData?.picture
                                  )}`
                                  : '/img/placeholder.png'
                              }
                              alt=""
                            />
                            <div className="content media-body  ml-md-4 w-100 text-center text-md-left">
                              <h4 className="m-0">{renderData?.name}
                                <span className=" ">
                                  {renderData?.mintUrl ? (
                                    <a
                                      href={`${renderData?.mintUrl}`}
                                      target="_blank"
                                      className="ml-4  px-1 border-primary text-primary border rounded"
                                    >
                                      Mint
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </h4>

                              {/* {wallet && <span>{display}</span>} */}
                              <span className="address">
                                address:
                                <a
                                  href={
                                    process.env.REACT_APP_ENVIRONMENT == 'DEV'
                                      ? `https://testnet.cronoscan.com/address/${contract_address}`
                                      : `https://cronoscan.com/address/${contract_address}`
                                  }
                                  target={'_blank'}
                                >
                                  {' '}
                                  {/* {contract_address} */}
                                  {contract_address?.substring(0, 5)}...
                                  {contract_address?.substring(37, 42)}
                                </a>
                              </span>

                              <p className="description">
                                {renderData?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 d-flex justify-content-center " style={{ gap: '20px' }}>
                          <div>

                            {console.log("33333333", renderData?.websiteUrl)}
                            {renderData?.websiteUrl ? (
                              // <button className="clientSocial h-auto">
                              <a
                                href={`${renderData?.websiteUrl}`}
                                target="_blank"
                              >
                                <img
                                  src="/img/website.png"
                                  className="text-white social-icons-t"
                                  alt="www"
                                  width={25}
                                  height={25}

                                />
                              </a>
                              // </button>
                            ) : (
                              ''

                            )}
                          </div>
                          <div>

                            {renderData?.mediumUrl ? (
                              <a href={`${renderData?.mediumUrl}`} target="_blank">
                                <img
                                  src="/img/medium.png"
                                  className="text-white social-icons-t"
                                  alt="www"
                                  width={25}
                                  height={25}
                                />
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>

                            {renderData?.socialUrl ? (
                              <a href={`${renderData?.socialUrl}`} target="_blank">
                                <img
                                  src="/img/social.png"
                                  className="text-white social-icons-t"
                                  alt="www"
                                  width={25}
                                  height={25}
                                />
                              </a>
                            ) : (
                              ''
                            )}
                          </div>

                        </div>
                        <div className="col-12 col-md-3 pt-3 pt-md-0">
                          <span className="time_data">
                            My Staked : {myStaked ?? ''} NFTs
                          </span>
                          <span className="time_data">
                            Total Staked : {totalStaked ?? ''} NFTs
                          </span>
                          {renderData?.expire_date && (
                            <Countdown
                              date={new Date(parseInt(renderData?.expire_date))}
                              intervalDelay={0}
                              precision={3}
                              renderer={renderer}
                            />
                          )}
                          {/* {console.log("wallet", wallet)} */}
                          {/* {console.log("renderData", renderData)} */}
                          {wallet &&
                            renderData?.creator_address?.toLowerCase() ==
                            wallet.accounts[0].address.toLowerCase() && (
                              <>
                                <input
                                  className="rewardDurationInput"
                                  type="number"
                                  placeholder="Enter week number"
                                  onChange={dateInputHandler}
                                />
                                <button
                                  className="btn input-btn mt-2 rewardDurationBtn"
                                  onClick={rewardsDurationHandler}
                                  disabled={!duration ? true : false}
                                >
                                  {btnLoading ? (
                                    <div className="col-12 text-center">
                                      <div
                                        className="spinner-border"
                                        role="status"
                                      >
                                        <span className="visually-hidden"></span>
                                      </div>
                                    </div>
                                  ) : (
                                    'SetRewardDuration'
                                  )}
                                </button>
                              </>
                            )}
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id={`collapse${renderData?._id}`}
                  className="collapse show "
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}

                  <div className="card-body">

                    {/* use this for prod    
                     chainId !== networkHashMap[connectedChain?.id]
                      */}
                    {console.log("chain id", chainId)}
                    {wallet?.accounts[0]?.address && wallet ? (
                      chainId !== networkHashMap[connectedChain?.id]
                        ? (
                          <div>
                            <span>Please Switch Your Network!</span>
                          </div>
                        ) : (
                          <>

                            <div className="row d-flex justify-content-between">
                              {/* Single Staking Item */}

                              <>
                                <div className="col-12">
                                  <p style={{
                                    position: 'absolute',
                                    zIndex: '100',
                                    top: '-22px',


                                  }}
                                    className='text-secondary'
                                  >

                                    {disbaleStakeUnstake ? 'Please claim first to enable stake and unstake' : ''}
                                  </p>
                                </div>

                                {!isCompleted && (
                                  <div className="col-6 col-md-2 mb-0 single-staking-item input-box d-flex justify-content-center">



                                    <div className="input-area d-flex flex-column">

                                      <button
                                        // href="#"
                                        className="btn input-btn mt-2 myButton"
                                        // onClick={modalHandler}

                                        onClick={modalHandler}
                                        disabled={disbaleStakeUnstake}
                                      >
                                        Stake
                                      </button>
                                    </div>
                                  </div>
                                )}


                                <div className="col-6 col-md-2 mb-0 single-staking-item input-box d-flex justify-content-center">
                                  <div className="input-area d-flex flex-column">
                                    <button
                                      // href="#"
                                      className="btn input-btn mt-2 myButton"
                                      // onClick={modalHandler}

                                      onClick={modalHandler2}
                                      disabled={disbaleStakeUnstake}

                                    >
                                      Unstake
                                    </button>
                                  </div>
                                </div>
                                <div className="col-6 col-md-2 single-staking-item input-box d-flex justify-content-center">
                                  <div className="input-area d-flex flex-column">
                                    <button
                                      // href="#"
                                      className="btn input-btn mt-2 myButton"
                                      onClick={handleClaim}
                                    >
                                      {loading ? (
                                        <div className="col-12 text-center">
                                          <div
                                            className="spinner-border"
                                            role="status"
                                          >
                                            <span className="visually-hidden"></span>
                                          </div>
                                        </div>
                                      ) : (
                                        'Claim'
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </>


                              <Modal open={isModalOpen} onClose={modalHandler}>
                                <NftInventoryModal
                                  renderData={renderData}
                                  handleStake={handleStake}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  handleClose={modalHandler}
                                  boostNft1Addr={boostNft1Addr}
                                />
                              </Modal>
                              <Modal open={isModalOpenUnstake} onClose={modalHandler2}>
                                <UnstakeNftInventoryModal
                                  renderData={renderData}
                                  handleUnstake={handleUnstake}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  handleClose={modalHandler2}
                                  unStake={'unstake'}

                                />
                              </Modal>
                              <Modal
                                open={isWithdrawModalOpen}
                                onClose={() => setIsWithdrawModalOpen(false)}
                              >
                                <StakingPoolInventoryModal
                                  renderData={renderData}
                                  handleWithdraw={handleWithdraw}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  handleClose={() => setIsWithdrawModalOpen(false)}
                                />
                              </Modal>
                              {/* Single Staking Item */}
                              {/* <div className='col-12 col-md-3 single-staking-item input-box'>
                            <span className='item-title mb-2'>Withdraw</span>
                            <div className='input-area d-flex flex-column'>
                              <button
                                // href="#"
                                className='btn input-btn mt-2'
                                onClick={withdrawModalHandler}
                              >
                                Withdraw
                              </button>
                            </div>
                          </div> */}

                              {/* Single Staking Item */}
                              {/* {!isCompleted && ( */}

                              <div className="col-12 col-md-3 single-staking-item input-box mb-0">
                                <span className="item-title mb-2">
                                  Estimated rewards
                                </span>
                                <div className="input-area d-flex flex-column">
                                  <h4 className="price m-0">
                                    {reward > 0
                                      ? parseFloat(reward / 10 ** decimals).toFixed(
                                        4
                                      )
                                      : 0}{' '}
                                    {symbol && symbol}
                                  </h4>
                                  <span className="reward my-2">
                                    {/* Rewards are depleted, ask admins to fund it. */}
                                  </span>
                                </div>
                              </div>
                              {/* )} */}
                              <div className="col-12 col-md-3 single-staking-item input-box">
                                <span className="item-title mb-2">Pool Rate</span>
                                <div className="input-area d-flex flex-column">
                                  <h4 className="price m-0">
                                    {rewardsPerWeek
                                      ? (
                                        rewardsPerWeek *
                                        10 ** -decimals
                                      ).toLocaleString('en-US', {
                                        maximumFractionDigits: 2
                                      })
                                      : 0}{' '}
                                    {symbol && symbol}/Week
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                    ) : (
                      <div className='text-center text-md-left'>
                        <span>Please Connect Your Wallet!</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <a
                      href="https://medium.com/@KangaDegens/rules-v2-staking-ae832d28c76c"
                      target="_blank"
                      className="text-center"
                    >
                      Stake rules & Info
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* )} */}
    </>
  );
};

const StakingNFT = () => {
  console.log("staking")
  const reduxDispatch = useDispatch();
  const params = useParams();
  console.log("params:", params)

  const userData = useSelector((state) => state?.state);
  console.log("userData:", userData)

  const [renderData, setRenderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // const { contract_address, collection_address, token_address } = renderData;

  // const [GetCollection, { loading }] = useLazyQuery(collectionData, {
  //   fetchPolicy: 'network-only'
  // });

  useEffect(async () => {
    try {
      

    if (userData?.collections?.length > 0) {
      console.log("userData:", userData)
      let _data = userData?.collections.filter(
        (e) => e.contract_address == params.id
      );
      setRenderData(_data[0]);
      setIsLoading(false);
    }
    else {
      console.log("else")
      let collectionData = await axios.get(`${process.env.REACT_APP_API_URL}nfts`);
      console.log("collectionData:", collectionData)
      reduxDispatch(
        saveCollection({
          collections: collectionData?.data
        })
      );
    }
  } catch (error) {
      console.warn("error:", error)
      
  }
  }, [params, userData?.collections]);

  useEffect(async () => {
    // let collectionData = await axios.get(process.env.REACT_APP_API_URL);
    // reduxDispatch(
    //   saveCollection({
    //     collections: collectionData?.data
    //   })
    // );

    return () => { };
  }, []);

  // useEffect(() => {
  //   GetCollection({
  //     variables: {
  //       filters: {
  //         contract_address: params.id,
  //       },
  //     },
  //     onCompleted: (data) => {
  //       setRenderData(data.Collections[0]);
  //       setIsLoading(false);
  //     },
  //     onError: (err) => {
  //       console.log(err);
  //     },
  //   });
  // }, [params]);

  return (
    <>
      <ToastContainer />
      {console.log("isss", isLoading)}
      {isLoading && (
        <section className="loader_sec stakingPool_loader">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="loader_wrapper">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div>{renderData && <StakingPool renderData={renderData} />}</div>
    </>
  );
};

export default StakingNFT;









