export const superAdmin = '0x6bb81CA8ec2F7ccbb08A5b1DF8c32781EF3e1C2d'
export const developerAddr = '0x892607bEF2b3c5d70917ee57FD1Ae55f38A63873'
export const chainId = '0x19'       // cronos  '0x19'  sepolia '11155111
export const checkAdmin = (useraddr) => {
    console.log("useraddr:", useraddr)
    if (useraddr == superAdmin.toLowerCase()) {
        return true
    }
    else if (useraddr == developerAddr.toLowerCase()) {
        return true
    }
    else {
        return false
    }
}